import _hyperscript from "./hyperscript";
import _request from "./request";
import _mountRedraw from "./mount-redraw";
import _route from "./route";
import _render from "./render";
import _parse from "./querystring/parse";
import _build from "./querystring/build";
import _parse2 from "./pathname/parse";
import _build2 from "./pathname/build";
import _vnode from "./render/vnode";
import _polyfill from "./promise/polyfill";
import _censor from "./util/censor";
var exports = {};
var hyperscript = _hyperscript;
var request = _request;
var mountRedraw = _mountRedraw;

var m = function m() {
  return hyperscript.apply(this, arguments);
};

m.m = hyperscript;
m.trust = hyperscript.trust;
m.fragment = hyperscript.fragment;
m.Fragment = "[";
m.mount = mountRedraw.mount;
m.route = _route;
m.render = _render;
m.redraw = mountRedraw.redraw;
m.request = request.request;
m.jsonp = request.jsonp;
m.parseQueryString = _parse;
m.buildQueryString = _build;
m.parsePathname = _parse2;
m.buildPathname = _build2;
m.vnode = _vnode;
m.PromisePolyfill = _polyfill;
m.censor = _censor;
exports = m;
export default exports;